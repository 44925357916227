import MainPage from "../pages/main/MainPage";
import ArticleDetail from "../pages/articles/ArticleDetail";
import Articles from "../pages/articles/Articles";
import Basket from "../pages/basket/Basket";
import Checkout from "../pages/basket/Checkout";
import OrderSuccess from "../pages/basket/OrderSuccess";
import Error from "../pages/error/Error";
import FAQ from "../pages/faq/FAQ";
import Catalog from "../pages/products/Catalog";
import ProductDetail from "../pages/products/ProductDetail";
import Products from "../pages/products/Products";
import SamplePage from "../pages/sample/SamplePage";
import Login from "../pages/user/Login";
import Profile from "../pages/user/Profile";
import Registration from "../pages/user/Registration";
import WishList from "../pages/user/WishList";
import { BASKET_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, ERROR_PAGE_ROUTE, FAQ_PAGE_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, ORDER_SUCCESS_PAGE_ROUTE, PRODUCTS_DETAIL_PAGE_ROUTE, PRODUCTS_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, USER_PAGE_ROUTE, WISHLIST_PAGE_ROUTE} from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: PRODUCTS_PAGE_ROUTE,
        Component: Products,
    },
    {
        path: PRODUCTS_DETAIL_PAGE_ROUTE,
        Component: ProductDetail
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: Articles
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: BASKET_PAGE_ROUTE,
        Component: Basket
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: ORDER_SUCCESS_PAGE_ROUTE,
        Component: OrderSuccess
    },
    {
        path: WISHLIST_PAGE_ROUTE,
        Component: WishList
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: Login
    },
    {
        path: REGISTRATION_PAGE_ROUTE,
        Component: Registration
    },
    {
        path: FAQ_PAGE_ROUTE,
        Component: FAQ
    },
    {
        path: USER_PAGE_ROUTE,
        Component: Profile
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    },
    {
        path: CATALOG_PAGE_ROUTE,
        Component: Catalog
    }
   
]

export const privateRoutes = [
    {
        path: USER_PAGE_ROUTE,
        Component: Profile
    },
]