import React, { useContext, useRef } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DropdownLanguage = observer(() => {
    const {app} = useContext(Context);

    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  return (
    app.languages?.length > 1 &&
    <div className="topbar__item">
        <div className={isActive ? "topbar-dropdown topbar-dropdown--opened" : "topbar-dropdown"} ref={dropdownRef}>
            <button className="topbar-dropdown__btn" type="button" onClick={() => setIsActive(!isActive)}>
                {app.localizationsItems?.language}: <span className="topbar__item-value">{app.lang}</span>
                <i className='fa fa-chevron-down'></i>
            </button>
            <div className="topbar-dropdown__body">
                <div className="menu menu--layout--topbar  menu--with-icons ">
                    <div className="menu__submenus-container"></div>
                    <ul className="menu__list">
                        {app.languages?.map((lang, index) =>
                            <li className="menu__item" key={index} onClick={() => app.setLanguages(lang.slug)}>
                                <div className="menu__item-link">
                                    {lang.title}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
})

export default DropdownLanguage