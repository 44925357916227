import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const AdvertisingBanner = observer(({elem}) => {
    const [banner, setBanner] = useState();

    const {app} = useContext(Context);

    useEffect(() => {
        if(elem){
            setBanner(elem.elements[0])
        }
    }, [elem])

  return (
    banner &&
    <div className="block block-banner">
        <div className="container">
            <NavLink to={banner.link} className="block-banner__body" style={banner.banner_font_color ? {color: banner.banner_font_color} : null}>
                <div className="block-banner__image block-banner__image--desktop">
                    <img src={banner.image} alt="" />
                </div>
                <div className="block-banner__image block-banner__image--mobile" style={{backgroundImage: "url('images/banners/banner-1-mobile.jpg')"}}></div>
                <div className="block-banner__title">{banner.title}</div>
                <div className="block-banner__text">{banner.subtitle}</div>
                <div className="block-banner__button">
                    <span className="btn btn-sm btn-primary">{app.localizationsItems?.buy_now}</span>
                </div>
            </NavLink>
        </div>
    </div>
  )
})

export default AdvertisingBanner