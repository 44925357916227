import React, { useContext, useState } from 'react'
import DropdownLanguage from '../simple/DropdownLanguage';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const TopBar = observer(() => {
    const {app} = useContext(Context);

    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [socials, setSocials] = useState();

    useEffect(() => {
        if(app.settings){
            setEmail(app.settings.find(it => it.type === "email")?.value)
            setAddress(app.settings.find(it => it.type === "address")?.value)
            setSocials(app.settings.find(it => it.type === "socials")?.value)
        }
    }, [app.settings])

  return (
    <div className="topbar__container container">
        <div className="topbar__row">
            {email?.map((em, index) =>
                <div className="topbar__item topbar__item--link" key={index}>
                    <i className='fa fa-envelope'></i>
                    <div>{em}</div>
                </div>
            )}
            {address?.map((em, index) =>
                <div className="topbar__item topbar__item--link" key={index}>
                    <i className='fa fa-location-arrow'></i>
                    <div>{em}</div>
                </div>
            )}
            <div className="topbar__spring"></div>
            <div className='top-socials'>
                {socials?.map((social, index) =>
                    <NavLink to={social.value} key={index} target='_blank'>  
                        <i className={
                            social.type === "facebook" ? "fab fa-facebook-f"
                            :
                            social.type === "twitter" ? "fab fa-twitter"
                            :
                            social.type === "instagram" ? "fab fa-instagram"
                            :
                            social.type === "youtube" ? "fab fa-youtube"
                            :
                            social.type === "telegram" ? "fab fa-telegram"
                            :
                            social.type === "whatsapp" ? "fab fa-whatsapp"
                            :
                            social.type === "tik-tok" ? "fab fa-tiktok"
                            :
                            "fab fa-facebook-f"
                        }
                        
                        ></i>
                    </NavLink>
                )}
            </div>
            <DropdownLanguage/>
        </div>
    </div>
  )
})

export default TopBar