import React, { useContext, useState } from 'react'
import img1 from "../../images/slides/slide-1-full.jpg"
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Utils from '../../services/utils';

const FormContact = observer(({item}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {}
        }

        for(var key in data){
            let temp = item.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.request(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
        }
        setLoading(false)
    }

  return (
    <div className='container block'>
        <div className="row">
            <div className="col-12 form-main">
                <div className="form-back-img">
                    <img src={item.form_image} alt="" />
                </div>
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <form className='bottom-form' onSubmit={handleSubmit(CustomSubmit)}>
                        <h3>{item.title}</h3>
                        <p>{item.subtitle}</p>
                        {item.elements?.map((elem, index) =>
                            <div className="form-group" key={index}>
                                <label>{elem.label}</label>
                                <input  {...register(elem.name)} required type="text" className="form-control form-control-lg" placeholder={elem.placeholder}/>
                            </div>
                        )}
                        <div className="row justify-content-end align-items-center">
                            <div className="col-auto">
                                {app.localizationsItems?.delivery}
                            </div>
                            <div className="col-auto">
                                <div className="form-group mb-0">
                                    <button className="btn btn-secondary" type='submit'>{app.localizationsItems?.form_button}</button>
                                </div>
                            </div>
                        </div>
                        <div className={visible ? 'form-success form-visible' : 'form-success'}>
                            <i className='fa fa-check-circle'></i>
                            <h2>{app.localizationsItems?.form_success_title}</h2>
                            <p>{app.localizationsItems?.form_success_text}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
})

export default FormContact