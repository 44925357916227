import http from "./http-common"
import {getData, postData} from "../handlers";

export default class CatalogServices {
    static categories = {
        index: () => {
            return getData(http, `/app/catalog/categories`);
        },
        view: (slug, queryString) => {
            return getData(http, `/app/catalog/categories/${slug}${queryString ? `?${queryString}` : ""}`);
        }
    }

    static products = {
        index: (queryString) => {
            return getData(http, `/app/catalog/products${queryString ? `?${queryString}` : ""}`);
        },

        latestProducts: () => {
            return getData(http, `/app/catalog/products`);
        },

        view: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}${queryString ? `${queryString}` : ""}`);
        }
    }
}