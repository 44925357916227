import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DropDownProduct = observer(({product}) => {
    const [loading, setLoading] = useState();
    const {app, user} = useContext(Context);

    const deleteItem = async () => {
        setLoading(true)
        const data = {
            uid: user.uid,
            product_id: product.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
            setLoading(false);
        }
    }


  return (
    <div className={loading ? "dropcart__product isLoading" : "dropcart__product"}>
        <div className="product-image dropcart__product-image">
            <NavLink to={`/${app.lang}/products/${product.slug}`} className="product-image__body">
                <img className="product-image__img" src={product.image ? product.image : img} 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                /> 
            </NavLink>
        </div>
        <div className="dropcart__product-info">
            <div className="dropcart__product-name"><NavLink to={`/${app.lang}/products/${product.slug}`}>{product.title}</NavLink></div>
            <ul className="dropcart__product-options">
                {product?.fields?.map((el, index) =>
                    <li key={index}>{el.name}: {el.value}</li>
                )}
            </ul>
            <div className="dropcart__product-meta">
                <span className="dropcart__product-quantity">{product.count}</span> ×
                <span className="dropcart__product-price">
                    {product.price == 0 ? `${app.localizationsItems?.not_specified}` : <>{Utils.numberWithSpaces(product.price)} {app.localizationsItems?.currency}</>}
                </span>
            </div>
        </div>
        <button type="button" className="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" onClick={deleteItem}>
            <i className='fa fa-trash'></i>
        </button>
    </div>
  )
})

export default DropDownProduct