import React from 'react'
import ShareBlock from '../simple/ShareBlock'
import ArticleDetailSidebar from './ArticleDetailSidebar'

const ArticleContainer = ({info}) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-8">
                <div className="block post post--layout--classNameic">
                    <div className="post__header post-header post-header--layout--classic">
                        <h1 className="post-header__title">{info.title}</h1>
                        <div className="post-header__meta">
                            <div className="post-header__meta-item"><a href="">{info.date}</a></div>
                        </div>
                    </div>
                    <div className="post__featured">
                        <img src={info.detail_image} alt=""/>
                    </div>
                    <div className="post__content typography ">
                        <p dangerouslySetInnerHTML={{ __html: info.description }}></p>
                    </div>
                    {/*<RelatedPosts/>*/}
                    <ShareBlock/>
                </div>
            </div>
            <ArticleDetailSidebar/>
        </div>
    </div>
  )
}

export default ArticleContainer