import React, { useContext, useEffect, useState } from 'react'
//import PriceToddle from '../simple/PriceToddle'
import LatestProducts from './LatestProducts'
import FilterItem from '../simple/FilterItem'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const ProductSidebar = observer(({setFilterData, params, open, setOpen, items}) => {
    const [filterItems, setFilterItems] = useState([]);
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const {slug} = useParams();

    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        let strQuery = query.getAll('categories[]');
        if(strQuery?.length){
            setFilterItems([filterItems, ...strQuery])
        }
    }, [])

    const handleFilter = () => {
        let str = '';
        if(filterItems){
            for(let i = 0; i<filterItems.length; i++){
                if(i === filterItems.length-1){
                    str+=`categories[]=${filterItems[i]}&`
                }else{
                    str+=`categories[]=${filterItems[i]}&`
                }
            }
        }
        setFilterData(str)
        navigate({
            search: str
        });
    }

    const handleReset = () => {
        setFilterData('')
        navigate({
            search: ''
        });
    }

    useEffect(() => {
        if(slug){
            setFilterItems([slug])
        }
    }, [slug])

  return (
    <div className="shop-layout__sidebar">
        <div className={open ? "block block-sidebar block-sidebar--offcanvas--mobile block-sidebar--open" : "block block-sidebar block-sidebar--offcanvas--mobile"}>
            <div className="block-sidebar__backdrop"></div>
            <div className="block-sidebar__body">
                <div className="block-sidebar__header">
                    <div className="block-sidebar__title">{app.localizationsItems?.filters}</div>
                    <button className="block-sidebar__close" type="button" onClick={() => setOpen(false)}>
                        <i className='fa fa-times'></i>
                    </button>
                </div>
                <div className="block-sidebar__item">
                    <div className="widget-filters widget widget-filters--offcanvas--mobile">
                        <h4 className="widget-filters__title widget__title">{app.localizationsItems?.filters}</h4>
                        <div className="widget-filters__list">
                            <FilterItem setFilterItems={setFilterItems} filterItems={filterItems}/>
                        </div>
                        <div className="widget-filters__actions d-flex">
                            <button className="btn btn-primary btn-sm" onClick={handleFilter}>{app.localizationsItems?.submit}</button>
                            <button className="btn btn-secondary btn-sm" onClick={handleReset}>{app.localizationsItems?.reset}</button>
                        </div>
                    </div>
                </div>
                <LatestProducts items={items}/>
            </div>
        </div>
    </div>
  )
})

export default ProductSidebar