import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import { useParams } from 'react-router-dom';
import CategoriesItem from './CategoriesItem';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const FilterItem = observer(({setFilterItems, filterItems}) => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(true);

    const {
        items,
    } = useFetchItems(CatalogServices.categories.index)

    const setFilter = (value) => {
        if(filterItems.find(v => v === value)){
            let arr = filterItems.filter(it => it !== value)
            setFilterItems(arr)
        }else{
            setFilterItems([...filterItems, value])
        }
    }

  return (
    <div className="widget-filters__item">
        <div className={open ? "filter filter--opened" : "filter"}>
            <button type="button" className="filter__title" onClick={() => setOpen(!open)}>
                {app.localizationsItems?.categories}
                <div className="filter__arrow">
                    <i className='fa fa-chevron-down'></i>
                </div>
            </button>
            <div className="filter__body" >
                <div className="filter__container">
                    <div className="filter-categories-alt">
                        <ul className="filter-categories-alt__list">
                            {items?.items?.map((it, index) =>
                                <CategoriesItem it={it} key={index} setFilter={setFilter} filterItems={filterItems}/>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default FilterItem