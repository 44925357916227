import React from 'react'
import img1 from "../../images/1.png"

const ReviewCard = ({card}) => {
  return (
    <div className='rewiev-card'>
        <div className='review-block'>
            <img src={img1} alt="" />
            <div className='review-container'>
                <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                <div className='review-title'>
                    {card.title}
                </div>
            </div>
        </div>
        {card.image && card.subtitle &&
        <div className='review-wrapper'>
            <img src={card.image} alt="" />
            <p>{card.subtitle}</p>
        </div>}
    </div>
  )
}

export default ReviewCard