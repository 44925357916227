import React, { useContext } from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import ProductsContainer from '../../components/complex/ProductsContainer'
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { Helmet } from 'react-helmet';
import MainBanner from '../../components/complex/MainBanner';
import Advantages from '../../components/complex/Advantages';
import Advantages2 from '../../components/complex/Advantages2';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import RelatedProducts from '../../components/complex/RelatedProducts';
import Blog from '../../components/complex/Blog';
import OfferGrid from '../../components/complex/OfferGrid';
import FaqComponent from '../../components/complex/FaqComponent';
import FormContact from '../../components/complex/FormContact';
import Reviews from '../../components/complex/Reviews';
import Partners from '../../components/complex/Partners';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const Products = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/catalog`)  
            }
        }
    }, [app.lang])

    const {
        items,
        setFilterData,
    } = useFetchItems(AppServices.page, 'products')

    useEffect(() => {
        if(query.get('term')){
            setFilterData(query.toString())
        }
    }, [])

  return (
    <div>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        <Breadcrumb item={items}/>
        <ProductsContainer/>
        {
            items?.elements?.map((elem, index) =>
                <div key={index}>
                    {
                        elem.type === "opening-slider" ?
                            <MainBanner elem={elem}/>
                        :
                        elem.type === "advantages" ?
                            <Advantages elem={elem}/>
                        :
                        elem.type === "advantages-2" ?
                            <Advantages2 elem={elem}/>
                        :
                        elem.type === "advertising-banner-2" ?
                            <AdvertisingBanner elem={elem}/>
                        :
                        elem.type === "products" ?
                            <RelatedProducts elem={elem}/>
                        :
                        elem.type === "articles" ?
                            <Blog elem={elem}/>
                        :
                        elem.type === "products-mix" ?
                            <OfferGrid/>
                        :
                        elem.type === "faq" ?
                            <FaqComponent item={elem}/>
                        :
                        elem.type === "form" ?
                            <FormContact item={elem}/>
                        :
                        elem.type === "reviews" ?
                            <Reviews item={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners item={elem}/>
                        :
                        <></>
                    }
                </div>    
            )
        }
    </div>
  )
})

export default Products