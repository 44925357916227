export const PROJECTS_ROUTE = '/projects'
export const BLOG_ROUTE = '/articles'
export const SERVICES_ROUTE = '/services'
export const EVENTS_ROUTE = '/events'

export const MAIN_PAGE_ROUTE = '/:lang'
export const PROJECTS_PAGE_ROUTE = '/:lang/projects'
export const PROJECTS_DETAIL_PAGE_ROUTE = '/:lang/projects/:slug'
export const SERVICES_PAGE_ROUTE = '/:lang/services'
export const SERVICES_DETAIL_PAGE_ROUTE = '/:lang/services/:slug'

export const SAMPLE_PAGE_SOUTE = '/:lang/page/:slug'

export const PRODUCTS_PAGE_ROUTE = '/:lang/catalog'
export const CATALOG_PAGE_ROUTE = '/:lang/catalog/:slug'
export const PRODUCTS_DETAIL_PAGE_ROUTE = '/:lang/products/:slug'

export const PRODUCT_ROUTE = '/products'


export const BLOG_PAGE_ROUTE = '/:lang/articles'
export const BLOG_DETAIL_PAGE_ROUTE = '/:lang/articles/:slug'


export const BLOG_RIGHT_SIDEVAR = "/blog/right-sidebar"
export const FAQ_PAGE_ROUTE = '/:lang/faq'

export const BASKET_PAGE_ROUTE = '/:lang/basket'
export const CHECKOUT_PAGE_ROUTE = '/:lang/checkout'
export const ORDER_SUCCESS_PAGE_ROUTE = '/:lang/order-success'

export const WISHLIST_PAGE_ROUTE = '/:lang/wishlist'

export const LOGIN_PAGE_ROUTE = '/:lang/login'
export const REGISTRATION_PAGE_ROUTE = '/:lang/registration'

export const USER_PAGE_ROUTE = '/:lang/user'

export const ERROR_PAGE_ROUTE = '/:lang/error'