import React, { useContext } from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import ProductContainer from '../../components/complex/ProductContainer'
import CatalogServices from '../../services/general/catalogServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { useNavigate, useParams } from 'react-router-dom';
import SimilarProducts from '../../components/complex/SimilarProducts';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import MainBanner from '../../components/complex/MainBanner';
import Advantages from '../../components/complex/Advantages';
import Advantages2 from '../../components/complex/Advantages2';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import RelatedProducts from '../../components/complex/RelatedProducts';
import Blog from '../../components/complex/Blog';
import OfferGrid from '../../components/complex/OfferGrid';
import FaqComponent from '../../components/complex/FaqComponent';
import FormContact from '../../components/complex/FormContact';
import Reviews from '../../components/complex/Reviews';
import Partners from '../../components/complex/Partners';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ProductDetail = observer(() => {
    const {slug} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const [ids, setIds] = useState();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug, null, app.lang);

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/products/${slug}`)
            }
        }
    }, [app.lang])

    useEffect(() => {
        if(info){
            let str = ''
            info.related_products?.forEach(element => {
                str += `ids[]=${element}&` 
            });
            setIds(str)
        }
    }, [info])
    
  return (
    info &&
    <div>
        <Helmet>
            <title>{info?.title}</title>
        </Helmet>
        <Breadcrumb item={info}/>
        <ProductContainer info={info}/>
        {ids && <SimilarProducts ids={ids}/>}
        {
            info?.elements?.map((elem, index) =>
                <div key={index}>
                    {
                        elem.type === "opening-slider" ?
                            <MainBanner elem={elem}/>
                        :
                        elem.type === "advantages" ?
                            <Advantages elem={elem}/>
                        :
                        elem.type === "advantages-2" ?
                            <Advantages2 elem={elem}/>
                        :
                        elem.type === "advertising-banner-2" ?
                            <AdvertisingBanner elem={elem}/>
                        :
                        elem.type === "products" ?
                            <RelatedProducts elem={elem}/>
                        :
                        elem.type === "articles" ?
                            <Blog elem={elem}/>
                        :
                        elem.type === "products-mix" ?
                            <OfferGrid/>
                        :
                        elem.type === "faq" ?
                            <FaqComponent item={elem}/>
                        :
                        elem.type === "form" ?
                            <FormContact item={elem}/>
                        :
                        elem.type === "reviews" ?
                            <Reviews item={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners item={elem}/>
                        :
                        <></>
                    }
                </div>    
            )
        }
    </div>
  )
})

export default ProductDetail