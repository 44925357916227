import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const LatestBlogs = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
    } = useFetchItems(ArticleServices.latestArticles)

  return (
    <div className="block-sidebar__item">
        <div className="widget-posts widget">
            <h4 className="widget__title">{app.localizationsItems?.latest_news}</h4>
            <div className="widget-posts__list">
                {items?.items.slice(0,6).map((card, index) =>
                    <div className="widget-posts__item" key={index}>
                        <div className="widget-posts__image">
                            <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                                <img src={card.preview_image ? card.preview_image : img} 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                /> 
                            </NavLink>
                        </div>
                        <div className="widget-posts__info">
                            <div className="widget-posts__name">
                                <NavLink to={`/${app.lang}/articles/${card.slug}`}>{card.title}</NavLink>
                            </div>
                            <div className="widget-posts__date">{card.date}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
})

export default LatestBlogs