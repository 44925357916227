import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useFetchItems from '../../hooks/useFetchItems';
import PartnersServices from '../../services/general/partnersServices';
import img from "../../images/placeholder.png"

const Partners = ({elem}) => {
    const settings = {
        nav: false,
        dots: false,
        loop: true,
        responsive: {
            1200: {items: 6},
            992: {items: 5},
            768: {items: 4},
            576: {items: 3},
            0: {items: 2}
        }
    };
    
    const {
        items,
    } = useFetchItems(PartnersServices.index)

  return (
    items?.items &&
    <div className="block block-brands">
        <div className="container">
            <div className="block-brands__slider">
                <OwlCarousel className="owl-carousel" {...settings}>
                    {items.items.map((card, index) =>
                        <div className="block-brands__item" key={index}>
                            <a href={card.link} target='_blank'>
                                <img src={card.image} alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
                            </a>
                        </div>
                    )}
                </OwlCarousel>
            </div>
        </div>
    </div>
  )
}

export default Partners