import React from 'react'
import Breadcrumb from '../../components/simple/Breadcrumb'
import BasketContainer from '../../components/complex/BasketContainer'

const Basket = () => {
  return (
    <div>
        <Breadcrumb/>
        <BasketContainer/>
    </div>
  )
}

export default Basket