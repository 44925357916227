import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { NavLink } from 'react-router-dom';
import Widgets from '../simple/Widgets';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const Footer = observer(() => {
    const {app} = useContext(Context);

    const [visible, setVisible] = useState(false);
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [text, setText] = useState();
    const [workTime, setWorkTime] = useState();
    const [widgets, setWidgets] = useState();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(app.settings){
            setSocials(app.settings.find(it => it.type === "socials"))
            setEmail(app.settings.find(it => it.type === "email"))
            setAddress(app.settings.find(it => it.type === "address"))
            setPhone(app.settings.find(it => it.type === "phone"))
            setText(app.settings.find(it => it.type === "footer_text"))
            setWorkTime(app.settings.find(it => it.type === "work_time"))
            setWidgets(app.settings.find(it => it.type === "widgets")?.value)
        }
    }, [app.settings])
  
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
        setVisible(true)
        } 
        else if (scrolled <= 300){
        setVisible(false)
        }
    };
  
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    
  return (
    <footer className="site__footer">
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="site-footer__widget footer-contacts">
                                <h5 className="footer-contacts__title">{app.localizationsItems?.contact_us}</h5>
                                {text?.value.map((em, index) => 
                                    <div key={index} className="footer-contacts__text">{em}</div>
                                )}
                                <ul className="footer-contacts__contacts">
                                    {address &&
                                        <li>
                                            <i className="footer-contacts__icon fas fa-globe-americas"></i>
                                                {address.value.map((em, index) => 
                                                    <p key={index}>{em}</p>
                                                )}
                                        </li>
                                    }
                                    {email &&
                                        <li>
                                            <i className="footer-contacts__icon far fa-envelope"></i>
                                            <div>
                                                {email.value.map((em, index) => 
                                                    <a href={`mailto:${em}`} key={index}>{em}</a>
                                                )}
                                            </div>
                                        </li>
                                    }
                                    {phone && 
                                        <li>
                                            <i className="footer-contacts__icon fas fa-mobile-alt"></i>
                                            <div>
                                                {phone.value.map((phone, index) => 
                                                    <p key={index}><a href={`tel:${phone}`}>{phone}</a></p>
                                                )}
                                            </div>
                                        </li>
                                    }
                                    {workTime &&
                                        <li>
                                            <i className="footer-contacts__icon far fa-clock"></i>
                                            {workTime.value.map((time, index) =>
                                                <p key={index}>{time}</p>
                                            )}
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="site-footer__widget footer-newsletter">
                                {socials?.value.find(el => el.value) &&
                                <>
                                    <div className="footer-newsletter__text footer-newsletter__text--social">
                                        {app.localizationsItems?.follow_us}
                                    </div>
                                    <div className="social-links footer-newsletter__social-links social-links--shape--circle">
                                        <ul className="social-links__list">
                                            {socials.value.map((social, index) =>
                                                social.value &&
                                                    <li className="social-links__item" key={index}>
                                                        <NavLink className={`social-links__link ` + social.type} to={social.value} target="_blank">
                                                            <i className={
                                                                social.type === "facebook" ? "fab fa-facebook-f"
                                                                :
                                                                social.type === "twitter" ? "fab fa-twitter"
                                                                :
                                                                social.type === "instagram" ? "fab fa-instagram"
                                                                :
                                                                social.type === "youtube" ? "fab fa-youtube"
                                                                :
                                                                social.type === "telegram" ? "fab fa-telegram"
                                                                :
                                                                social.type === "whatsapp" ? "fab fa-whatsapp"
                                                                :
                                                                social.type === "tik-tok" ? "fab fa-tiktok"
                                                                :
                                                                "fab fa-facebook-f"
                                                            }>
                                                            </i>
                                                        </NavLink>
                                                    </li>

                                            )}
                                        </ul>
                                    </div>
                                </>}
                            </div>
                        </div>
                        {items?.map((elem, index) =>
                            <div className="col-12 col-md-3 col-lg-4" key={index}>
                                <div className="site-footer__widget footer-links">
                                    <h5 className="footer-links__title">{elem.title}</h5>
                                    <ul className="footer-links__list">
                                        {elem.items?.map((el, index) =>
                                            <li className="footer-links__item" key={index}><NavLink to={el.value} target={el.type} className="footer-links__link">{el.title}</NavLink></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        © Panama.kz
                    </div>
                </div>
            </div>
            <div className={visible ? "totop totop--show" : "totop"} onClick={scrollToTop}>
                <div className="totop__body">
                    <div className="totop__start"></div>
                    <div className="totop__container container"></div>
                    <div className="totop__end">
                        <button type="button" className="totop__button">
                            <i className='fa fa-chevron-up'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {widgets && <Widgets item={widgets}/>}
  </footer>
  )
})

export default Footer