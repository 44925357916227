import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import AddToCart from '../complex/AddToCart';
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ToCartModal = observer(({show, setShow, slug}) => {
    const handleClose = () => setShow(false);
    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div>
                <div className='product-wrapper'>
                    <img src={info?.image} alt="" />
                    <NavLink to={`/${app.lang}/products/${slug}`}>
                        <h4>{info?.title}</h4>
                    </NavLink>
                </div>
                <AddToCart card={info}/>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ToCartModal