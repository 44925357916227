import React from 'react'
import img from "../../images/placeholder.png"

const Advantages2 = ({elem}) => {

  return (
    <div className='advantages2' style={{backgroundImage: `url(${elem.steps_image})`}}>
        <div className="container">
            <div className="row justify-content-center">
                {elem.elements?.map((card, index) =>
                    <div key={index} className='col-sm-12 col-md-6 col-xl-4'>
                        <div className='advantages2-item'>
                            <div className='text'>
                                <h4>{card.title}</h4>
                                {card.subtitle && <p>{card.subtitle}</p>}
                            </div>
                            <img src={card.image} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Advantages2