import React, { useContext } from 'react'
import { useState } from 'react'
import CheckoutModal from '../modals/CheckoutModal';
import { useForm } from 'react-hook-form';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const AddToCart = observer(({card}) => {
    const [count, setCount] = useState(1);
    const {app, user} = useContext(Context);
    const [checkoutModal, setCheckoutModal] = useState(false);
    
    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const [visible, setVisible] = useState(false);

    const changeCount = (e) => {
        setCount(e.target.value)
    }

    const plusCount = () => {
        setCount(count+1)
    }

    const minusCount = () => {
        if(count > 1){
            setCount(count-1)
        }
    }

    const addToBasket = async(data) => {
        setVisible(true);

        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: card.id,
            count: count,
            fields: []
        }

        for(var key in data){
            newData.fields.push(
                {name: key, value: data[key]}
            )
        }
        
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!user.uid){
                user.setUid(res.content.uid)
            }
            user.setBasketChange(Math.random().toString(16))
        }

        setTimeout(() => {
            setVisible(false)
        }, 3000);
    }

  return (
    <form onSubmit={handleSubmit(addToBasket)}>
        {card?.traits?.map((it, index) =>
            <div className="form-group product__option" key={index}>
                <label className="product__option-label">{it.title}</label>
                <div className="input-radio-label">
                    <div className="input-radio-label__list">
                        {it.elements?.map((el, index) =>
                            <label key={index}>
                                <input type="radio" {...register(it.title)} value={el.name}/>
                                <span>{el.name}</span>
                            </label>
                        )}
                    </div>
                </div>
            </div>
        )}
        <div className="product__options">
            <div className="form-group product__option">
                <label className="product__option-label" >{app.localizationsItems?.count}</label>
                <div className="product__actions">
                    <div className="product__actions-item">
                        <div className="input-number product__quantity">
                            <input id="product-quantity" className="input-number__input form-control form-control-lg" type="number" value={count}
                                onChange={e => changeCount(e)}
                            />
                            <div className="input-number__add" onClick={plusCount}></div>
                            <div className="input-number__sub" onClick={minusCount}></div>
                        </div>
                    </div>
                    <div className="product__actions-item product__actions-item--addtocart">
                        <button type='submit' className={visible ? "btn btn-outline-cart btn-lg product-card__addtocart" : "btn btn-primary btn-lg"}
                            disabled={!card?.availability}
                        >
                            {visible ? app.localizationsItems?.on_cart : app.localizationsItems?.to_cart}
                        </button>
                    </div>
                    {/*<div className="product__actions-item product__actions-item--wishlist">
                        <button type="button" className="btn btn-secondary btn-svg-icon btn-lg" title="Wishlist">
                            <i className='fa fa-heart'></i>
                        </button>
                    </div>*/}
                    <div className="product__actions-item product__actions-item--wishlist">
                        <button type="button" className="btn btn-secondary btn-lg" title="Wishlist" onClick={() => setCheckoutModal(true)} disabled={!card?.availability}>
                            {app.localizationsItems?.checkout}
                        </button>
                    </div>
                </div>
            </div>
            {checkoutModal && <CheckoutModal show={checkoutModal} setShow={setCheckoutModal} productName={card?.title}/>}
        </div>
    </form>
  )
})

export default AddToCart