import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Fancybox from './FancyBox';
import img from "../../images/placeholder.png"

const ProductSlider = ({slider}) => {
    const settings = {
        dots: false,
        margin: 10,
        items: 1,
        URLhashListener: true,
        autoplayHoverPause: true,
        startPosition: 'URLHash'
    };

    const settings2 = {
        dots: false,
        margin: 10,
        responsive: {
            1200: {items: 5},
            992: {items: 4},
            768: {items: 3},
            480: {items: 5},
            380: {items: 4},
            0: {items: 3}
        }
    };

  return (
    <>{
    slider.length >=1 ?
    <div className="product__gallery">
        <div className="product-gallery">
            <div className="product-gallery__featured">
                <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    <OwlCarousel className="owl-carousel" id="product-image" {...settings} >
                        {slider.map((slide, index) =>
                            <div className="product-image product-image--location--gallery" data-hash={`slide${index}`} key={index}>
                                <a href={slide} data-width="700" data-height="700" className="product-image__body" target="_blank" data-fancybox="gallery">
                                    <img className="product-image__img" src={slide} alt=""/>
                                </a>
                            </div>
                        )}
                    </OwlCarousel>
                </Fancybox>
            </div>
            <div className="product-gallery__carousel">
                <OwlCarousel className="owl-carousel" id="product-carousel" {...settings2}>
                    {slider.map((slide, index) =>
                        <a href={`#slide${index}`} className="product-image product-gallery__carousel-item" key={index}>
                            <div className="product-image__body">
                                <img className="product-image__img product-gallery__carousel-image" src={slide} alt=""/>
                            </div>
                        </a>
                    )}
                </OwlCarousel>
            </div>
        </div>
    </div>
    :
    <div className="product__gallery">
        <div className="product-gallery">
            <img src={img} alt="" />
        </div>
    </div>
    }</>
  )
}

export default ProductSlider